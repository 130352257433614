import { Component, Input, NgZone, ViewChild } from '@angular/core';
import { App,URLOpenListenerEvent  } from "@capacitor/app";
import { Router } from "@angular/router";
import { DeviceService } from "./services/device.service";
import { StorageService } from "./services/storage.service";
import { IonContent, IonTabs, Platform } from "@ionic/angular";
import { SplashScreen } from "@capacitor/splash-screen";
import { Location } from "@angular/common";
import { environment } from "../environments/environment";
import { LocaldbService } from "./services/localdb.service";
import { ChatService } from "./services/chat.service";
import { EventsService } from "./services/events.service";
import { SessionService } from './services/session.service';
import { NotificationService } from './services/notification.service';
import { BiometricsService } from './services/biometrics.service';
import { AlertService } from './services/alert.service';
import { ActionSheetController,ModalController } from "@ionic/angular";


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    private appIsActive: boolean = true;
    public color: string = '';
    public newMessages = 0;

    public settingsColor = '';
    public profileColor = '';
    public dialogsColor = '';
    public searchColor = '';
    public whodoeswhatColor = '';

    @ViewChild('tabs', { static: false }) tabs: IonTabs;

    constructor(
        private router: Router,
        private zone: NgZone,
        private deviceService: DeviceService,
        private storageService: StorageService,
        private platform: Platform,
        private location: Location,
        private localDBService: LocaldbService,
        private chatService: ChatService,
        private eventService: EventsService,
        private sessionService: SessionService,
        private notificationService: NotificationService,
        private biometricsService: BiometricsService,
        private alertService: AlertService,
        private actionSheetController: ActionSheetController,
        private modalController: ModalController
    ) {
        this.eventService.getConnectionState().subscribe((data) => {
            if (data === 'connecting') {
                this.color = 'warning';
            } else if (data === 'connected') {
                this.color = 'success';
            } else {
                this.color = '';
            }
        });

        this.eventService.getUnlockEvent().subscribe((data) => {
            console.log("APP UNLOCKEVENT")
            this.checkUnlock(data)
        })

        console.log("installing appURLOpen handler")
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            console.log("received appUrlOpen-event",event)
			this.zone.run(() => {
                this.router.navigate(["/dialoglist"]);

                //window.alert(event.url)
                /*
				const domain = 'devdactic.com';
				const pathArray = event.url.split(domain);
				const appPath = pathArray.pop();
				if (appPath) {
					this.router.navigateByUrl(appPath);
				}
                */
			});
		});        

        this.initApp()
    }

    async updateCounter() {
        try {
            let dialogs = await this.localDBService.getDialogs();
            let counter = 0;
            dialogs.forEach((item) => {
                if (item.new_messages > 0) {
                    counter += item.new_messages;
                }
            });
            this.newMessages = counter;
        } catch (error) {

        }
    }

    async setCurrentTab(e) {
        this.settingsColor = '';
        this.profileColor = '';
        this.dialogsColor = '';
        this.searchColor = '';
        this.whodoeswhatColor = '';

        try {
            await this.modalController.dismiss()
        } catch (error) {
            console.log("TAB: no modals")
        }

        const color = 'color: #ff4961'
        console.log("TAB",e.tab,document.location.href);

        if (e.tab == 'help' || e.tab == 'devices') {
            this.settingsColor = color
            return
        }
        if (e.tab == 'profile-edit' || e.tab == 'gallery-edit' || e.tab == 'visitors') {
            this.profileColor = color
            return
        }
        if (document.location.href.endsWith('profile/self')) {
            this.profileColor = color
            return
        }
        if (e.tab == 'dialog' || e.tab == 'contacts') {
            this.dialogsColor = color
            return
        }
        if (e.tab == 'profile-search') {
            this.searchColor = color
        }
    }

    async initApp() {
        this.platform.ready().then(async () => {

/*
            this.deeplinks.route({
                '/about-us': HomePage,
                '/products/:productId': HelpPage
              }).subscribe((match) => {
                // match.$route - the route we matched, which is the matched entry from the arguments to route()
                // match.$args - the args passed in the link
                // match.$link - the full link data
                console.log('Successfully matched route', match);
              },
              (nomatch) => {
                // nomatch.$link - the full link data
                console.error('Got a deeplink that didn\'t match', nomatch);
              });
*/
            await SplashScreen.hide();
            
            await this.checkUnlock("appstart");

            this.eventService.getUpdate().subscribe((data) => {
                this.showLocalNotifications(data);
                this.updateCounter();
            });
            this.updateCounter();

            App.addListener('appStateChange', async ({ isActive }) => {

                if (!isActive) {
                    return
                }

                if ((await this.checkUnlock("activate")) == false) return

                if (await this.sessionService.hasSessionData()) {
                    this.chatService.interact()
                }
            });

            // this is only for testing on webview and can be deleted for production version
            document.addEventListener('keyup', (event) => {
                if (event["key"] == 'Control') {
                    if (environment._nav_initial != null) {

                        const outside_urls = ["/", "/signin", "/signinup", "/welcome", "/verification"];
                        if (outside_urls.indexOf(environment._nav_initial) != -1) {
                            console.log("outside to inside: dont go back");
                            return false;
                        } else {
                            console.log("outside to outside: go back");
                            this.location.back();
                        }
                    } else {
                        return false;
                    }
                }
            });

            document.addEventListener('ionBackButton', (ev) => {
                if (environment._nav_initial != null) {
                    const outside_urls = ["/", "/signin", "/signinup", "/welcome", "/verification"];
                    if (outside_urls.indexOf(environment._nav_initial) != -1) {
                        console.log("outside to inside: dont go back");
                        return false;
                    } else {
                        console.log("outside to outside: go back");
                        this.location.back();
                    }
                } else {
                    console.log("no navinfo available: dont go back");
                    return false;
                }

              
            });

        });

    }

    private async showLocalNotifications(chat) {

        /*
        let isNative = await this.deviceService.isNativeApp()
        if (isNative) {
            if (!this.deviceService.paused) return;
        } else {
            if (document.visibilityState === "visible") return;
        }
        */

        console.log("LOCALNOT",chat)
        if (chat.messages == null) return;
        if (chat.messages.length == 0) return;

        for (let j = 0; j < chat.messages.length; j++) {
            let text = chat.messages[j].message
            if (this.notificationService.suppressLocalNotification(chat.user_handle)) {
                continue
            }
            if (chat.messages[j]._type != "message") {
                continue;
            }
            if (chat.messages[j]._deleted) {
                continue;
            }
            if (chat.messages[j]._issecret) {
                text = "*****"
            }

            let num_attachments = 0
            try {
                if (chat.messages[j].attachments != null) {
                    num_attachments = chat.messages[j].attachments.length
                }
            } catch(error) {

            }

            if (num_attachments > 0) {
                this.notificationService.showLocalNotification(
                    "Neues Bild von " + chat.user_nick ,
                    text,
                    "/dialog/" + chat.user_handle
                );
            } else {
                this.notificationService.showLocalNotification(
                    "Neue Nachricht von " + chat.user_nick,
                    text,
                    "/dialog/" + chat.user_handle
                );
            }
        }
      
    }


    public async blackOutIfRequired() {
        let unlockRequired = await this.isUnlockRequired("activate")
        if (!unlockRequired) {
            this.blackOut(false)
            return true
        }

        this.blackOut(true)
    }

    public blackOut(v) {
        let elem = document.querySelector('#black') as HTMLElement
        if (v) {
            elem.style.display = 'block';
        } else {
            elem.style.display = 'none';
        }
    }

    public async isUnlockRequired(mode) {
        const TOLERANCE_MILLIS = 500
        // if the app has not been in the background, there is no need to unlock
        if (this.deviceService.pauseTime == null) {
            console.log("APP ISUNLOCKREQUIRED",mode,false,"app was not paused")
            return false
        } 

        // if the app called a native dialog, no lock screen shall be shown upon return
        if (this.deviceService.inNativeDialog) {
            console.log("APP ISUNLOCKREQUIRED",mode,false,"showing native dialog")
            return false
        }

        // quick app switches (like: clicking on a local notification) will not require unlocking
        let millisSincePause = Date.now()-this.deviceService.pauseTime
        console.log("APP MILLIS SINCE PAUSE",millisSincePause)
        if (millisSincePause < TOLERANCE_MILLIS) {
            console.log("APP ISUNLOCKREQUIRED",mode,false)
            return false
        }

        // do not ask for verification more often than some time
        if (this.biometricsService.recentlyVerified(TOLERANCE_MILLIS)) {
            console.log("APP ISUNLOCKREQUIRED",mode,false,"was recently verified. no need to verify again")
            return false
        }

        // verification error page has a button to start verification again
        // is a better ux than just popping ob the verification screen over and over again
        if (window.location.href.indexOf("/failure?errorclass=identity&") !== -1) {
            console.log("APP ISUNLOCKREQUIRED",mode,false,"identity-error page.")
            return false
        }

        // is it even configured to have unlock feature!?
        // (is not on the top of this function because it costs 3 await-calls, the other checks are cheaper)
        let mustUnlock = await this.storageService.getUnlockToggle()
        let mustUnlockEverytime = await this.storageService.getUnlockEverytimeToggle()
        let canUnlock = await this.biometricsService.canVerifyIdentity()
        if (!mustUnlock || !canUnlock) {
            console.log("APP ISUNLOCKREQUIRED",mode,false,"not configured or not available")
            return false
        }

        // when app is started
        if (mode == "appstart") {
            console.log("APP ISUNLOCKREQUIRED",mode,false,"appstart")
            return true
        }

        // when app comes to foreground
        console.log("APP ISUNLOCKREQUIRED",mode,mustUnlockEverytime)
        return mustUnlockEverytime
    }

    public async checkUnlock(mode) {
        console.log("APP CHECKUNLOCK",mode)

        // appstart is much different from "activate". check seperately
        let unlockRequired = false
        if (mode == "appstart") {
            unlockRequired = await this.storageService.getUnlockToggle()
        } else {
            unlockRequired = await this.isUnlockRequired(mode)
        }

        if (!unlockRequired) {
            this.blackOut(false)
            return true
        }

        this.blackOut(true)

        let res = await this.biometricsService.verifyIdentity()
        if (res) {
            this.blackOut(false)
            return true
        }

        this.alertService.failure("identity","",async () => {
            this.blackOut(false)

            let res = await this.biometricsService.verifyIdentity()
            if (!res) return false;
            this.router.navigateByUrl("/dialoglist")
            return true
        })

        this.blackOut(false)
        return false

    }

    public async showMore() {
        const actionSheet = await this.actionSheetController.create({
            backdropDismiss: true,
            animated: true,
            mode: 'md',
            cssClass: '',
            buttons: [
              {
                text: 'Mein Profil',
                icon: 'person-outline',
                data: 'self'
              },
              {
                text: 'Einstellungen',
                icon: 'settings',
                data: 'settings'
              },
            ]
        });
        await actionSheet.present()
        let action = await actionSheet.onDidDismiss()

        if (action.data == "self") {
            await this.router.navigate(['/profile/self']);
            return
        }
        if (action.data == "settings") {
            await this.router.navigate(['/settings']);
            return
        }
        
    }

}
