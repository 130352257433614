import { Injectable } from '@angular/core';
import { Contacts } from "@capacitor-community/contacts";
import { Device } from "@capacitor/device";
import { App } from "@capacitor/app";
import { Platform } from '@ionic/angular';


const APPVERSION = "0.8.3"
const APPBUILDDATE = "07.10.2024"

const DEVICE_INFO_INTERVAL = 1 * 60 * 1000;

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    public paused = false
    public pauseTime = null
    public inNativeDialog = false
    
    constructor(
        private platform: Platform
    ) { 

        this.platform.pause.subscribe(async () => {
            this.paused = true
            this.pauseTime = Date.now()
            console.log("APP","PAUSED")
        });        
        this.platform.resume.subscribe(async () => {
            this.paused = false
            this.pauseTime = null
            console.log("APP","RESUMED")
        });

    }


    private _deviceInfo: any;
    private _deviceInfoTime: number = 0;

    private async _getInformation(): Promise<any> {
        console.log("DEVICE","GETINFORMATION")

        const info = await Device.getInfo();
        const uuid = await Device.getId();
        const systemLanguageCode = await Device.getLanguageCode();

        let systemBattery = {
            isCharging: false,
            batteryLevel: 100,
        };

        try {
            let batteryInfo = await Device.getBatteryInfo();
            systemBattery.isCharging = batteryInfo.isCharging;
            systemBattery.batteryLevel = batteryInfo.batteryLevel;
        } catch (error) {}

        let appName = '';
        let appId = '';
        let appBuild = '';
        let appVersion = '';

        if (info.platform == 'android' || info.platform == 'ios') {
            const appInfo = await App.getInfo();
            appName = appInfo.name;
            appId = appInfo.id;
            appBuild = appInfo.build;
            appVersion = appInfo.version;
        } else {
            appName = 'WebDeSadeMessenger';
            appId = '1234';
            appBuild = '5678';
            appVersion = '1';
        }

        return {
            uuid: uuid.identifier,
            appVersion: appVersion,
            appBuild: appBuild,
            appId: appId,
            appName: appName,
            systemLanguage: systemLanguageCode,
            webViewVersion: info.webViewVersion,
            manufacturer: info.manufacturer,
            model: info.model,
            operatingSystem: info.operatingSystem,
            osVersion: info.osVersion,
            platform: info.platform,
            isVirtual: info.isVirtual,
            batteryIsCharging: systemBattery.isCharging,
            batteryLevel: systemBattery.batteryLevel
        };
    }

    public async getInformation(): Promise<any> {
        const now = Date.now();
        if ((now-this._deviceInfoTime) < DEVICE_INFO_INTERVAL) {
            if (this._deviceInfo != null) return this._deviceInfo;
        }

        this._deviceInfoTime = now;
        this._deviceInfo = await this._getInformation();
        return this._deviceInfo;
    }


    public async clientInfo() {
        const info = await this.getInformation();
        const webviewtype = await this.getWebviewType()
        return (info.uuid + '|' + webviewtype + '|' + info.osVersion + '|' + APPVERSION);
    }

    public async isNativeApp() {
        return ((await this.getInformation()).platform == 'android' || (await this.getInformation()).platform == 'ios');
    }

    public async isIOS() {
        return ((await this.getInformation()).operatingSystem == 'ios')        
    }

    public async isAndroid() {
        return ((await this.getInformation()).operatingSystem == 'android')        
    }

    public async getWebviewType() {
        let info = await this.getInformation()
        let platform = info.platform
        if (platform == "web") {
            return platform + "@" + info.operatingSystem
        }
        return platform
    }

    public async isSafariType() {
        let webviewtype = await this.getWebviewType()
        return ((webviewtype == "web@mac") || (webviewtype == "web@ios"))
    }

    public async hasContactsPermission() {
        // browser means no addressbook, we map that to true
        if (!(await this.isNativeApp())) return true;
        try {
            this.inNativeDialog = true
            const check = await Contacts.checkPermissions()
            this.inNativeDialog = false
            return check.contacts == 'granted'
        } catch (error) {
            this.inNativeDialog = false
            return false;
        }

    }

    public async requestContactsPermission() {
        try {
            this.inNativeDialog = true
            let permissions = await Contacts.requestPermissions();
            this.inNativeDialog = false
            return permissions.contacts + ""
        } catch (error) {
            this.inNativeDialog = false
        }
        return "error"
    }

    private async loadContactsMobile() {

        if (!(await this.hasContactsPermission())) {
            return [];
        }

        // get contacts from addressbook
        let result = [];
        try {
            let data = await Contacts.getContacts({
                projection: {
                    name: true,
                    phones: true
                }
            });

            for (let i = 0; i < data.contacts.length; i++) {
                let contact = data.contacts[i]
                contact.contactId
                if (contact.contactId == null) continue
                if (contact.phones == null) continue
                if (contact.phones.length == 0) continue
                result.push(contact)
            }
        } catch (error) {
            console.log("DEVICE","LOADCONTACTSMOBILE",error);
        }
        return result;
    }

    private async loadContactsMock() {
        let mock = [];

        mock.push({
            contactId: 1,
            name: {"display": "(Lokal) Rezar"},
            phones: [{"number": '+491777878785',"isPrimary": true,"type": "other"}]
        });
        mock.push({
            contactId: 2,
            name: {"display": '(Lokal) Ingo'},
            phones: [{"number": '+491728534917',"isPrimary": true,"type": "other"}]
        });
        mock.push({
            contactId: 3,
            name: {"display": '(Lokal) Peter'},
            phones: [{"number": '+491001',"isPrimary": true,"type": "other"}]
        });
        mock.push({
            contactId: 4,
            name: {"display": '(Lokal) Paul'},
            phones: [{"number": '+491002',"isPrimary": true,"type": "other"}]
        });
        mock.push({
            contactId: 5,
            name: {"display": '(Lokal) Mary'},
            phones: [{"number": '+491003',"isPrimary": true,"type": "other"}]
        });

        return mock;
    }


    public async loadContacts() {
        let contacts = [];
        if (await this.isNativeApp()) {
            contacts = await this.loadContactsMobile()
        } else {
            contacts = []
            // contacts = await this.loadContactsMock();
        }

        if (contacts == null) {
            return [];
        }

        return contacts;
    }

    public getAppVersion() {
        return APPVERSION + " (" + APPBUILDDATE + ")"
    }

    public async pickContact() {
        if (!(await this.isNativeApp())) {
            return null
        }
        if (!(await this.hasContactsPermission())) {
            let perm = await this.requestContactsPermission()
            if (perm != "granted") return
        }

        let contact = null

        try {
            // if you hit "back" in the contacts-dialog, this call will NOT return at all
            console.log("PICKCONTACT BEGIN")
            let res = await Contacts.pickContact({
                projection: {
                    name: true,
                    phones: true,
                    image: true
                }
            })
            console.log("PICKCONTACT END")

            // upon return, we dont receive a "RESUME" event. we have to fake this one here
            this.resetToActive()

            if (res == null) return null
            if (res["contact"] == null) return null
            contact = res["contact"]
        } catch (error) {
            console.log("PICKCONTACT ERROR",error)
            contact = null
        }

        return contact
    }

    public resetToActive() {
        this.inNativeDialog = false
        this.paused = false
        this.pauseTime = null
    }


}
